import React, { useEffect, useState } from "react";
import axios from "axios";
import cn from "classnames";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import DataTable from "react-data-table-component";
import styles from "./User.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { usersList } from "../../environment";
import {
  notifySuccess,
  notifyError,
} from "../../apiConnection/notification.api";
import DeleteUser from "./DeleteUser";
import { customStyles } from "../../helpers/commonStyles";
import SearchMain from "../../components/MainSearch";
import { FaEye, FaArrowRotateRight, FaBan,FaRegShareFromSquare } from "react-icons/fa6";
import ReportModal from "../../components/ReportModal";
import ChangeEIN from "../Reports/ChangeEIN";

const Organizations = () => {
  const history = useHistory();
  const [users, setUsers] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [reportId, setReportId] = useState("");
  const [reportType, setReportType] = useState("Organization");
  const [search, setSearch] = useState(null);
  const [changeEin, setChangeEin] = useState(false);
  const [organization, setOrganization] = useState("");


  const getUsersListing = async () => {
    const users = await axios.get(usersList, {
      params: {
        role: "organization",
        ...(search && { search }),
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (users?.data?.data) {
      setUsers(users?.data?.data);
    }
  };
  useEffect(() => {
    getUsersListing();
  }, []);
  useEffect(() => {
    let timerId;
    if (search?.length > 3 || !search) {
      // Clear any existing timer
      clearTimeout(timerId);

      // Set a new timer to trigger getUsersListing after 1 second
      timerId = setTimeout(() => {
        getUsersListing();
      }, 500);
    }

    // Cleanup function to clear the timer if component unmounts or search changes
    return () => {
      clearTimeout(timerId);
    };
  }, [search]);

  const columns = [
    {
      name: "Name",
      selector: (row) => `${row.firstName} ${row.lastName}`,
    },
    {
      name: "Profile Url",
      selector: (row) => (
        <a
          href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/user/${row.username}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {`${process.env.REACT_APP_FRONTEND_BASE_URL}/user/${row.username}`}
        </a>
      ),
    },
    {
      name: "Nonprofit Name",
      selector: (row) => `${row.nonProfitName}`,
    },
    {
      name: "EIN Number",
      selector: (row) => `${row?.einNumber ? row?.einNumber : 'N/A'}`,
    },
    {
      name: "Email Address",
      selector: (row) => row.email,
    },
    {
      name: "Account Status",
      // selector: (row) => row.emailVerified,
      cell: (row) => (
        <>
          {row?.emailVerified === true ? (
            <span className="badge rounded-pill bg-success">Verified</span>
          ) : (
            <span className="badge rounded-pill bg-danger">Not Verified</span>
          )}
        </>
      ),
    },
    // {
    //   name: '2FA Enabled',
    //   cell: (row) => (
    //     <>
    //       {row?.is2faEnabled === true ? (
    //         <span className='badge rounded-pill bg-success'>Active</span>
    //       ) : (
    //         <span className='badge rounded-pill bg-warning'>Not active</span>
    //       )}
    //     </>
    //   ),
    // },
    {
      name: "Created Date",
      selector: (row) => moment(row.createdAt).format("MMMM, DD, YYYY"),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <button style={{width:'80px'}}
            className={styles.viewButton}
            onClick={() => {
              setReportId(row?._id);
              setShowModal(true);
            }}
          >
            <FaEye /> Report
          </button>
          <button style={{width:'110px', fontSize: '12px'}}
            className={styles.addButton}
            onClick={() => {
              setOrganization(row)
              setChangeEin(true);
            }}
          >
            <FaRegShareFromSquare /> Change EIN
          </button>
          {/* <button
            className={styles.recoverButton}
            // onClick={() => {
            //   setDeleteId(row?.reports[0]?._id);
            //   setIsRecoverShow(true);
            //   setActionType('recover');
            //   setModalTitle('Recovery Alert');
            //   setModalMessage(
            //     'Are you sure you want to recover this organization ?'
            //   );
            // }}
          >
            <FaArrowRotateRight /> Reinstate
          </button> */}
          {/* <button
            className={styles.banButton}
            // onClick={() => {
            //   setDeleteId(row?.reports[0]?._id);
            //   setIsRecoverShow(true);
            //   setActionType('ban');
            //   setModalTitle('Ban Alert');
            //   setModalMessage(
            //     'Are you sure you want to ban this organization ?'
            //   );
            // }}
          >
            <FaBan /> Ban
          </button> */}
        </>
      ),
    },
    // {
    //   name: 'Actions',
    //   cell: (row) => (
    //     <>
    //       <button
    //         className={styles.editButton}
    //         onClick={() =>
    //           history.push(`edit-role/${row?._id}`, {
    //             row,
    //           })
    //         }
    //       >
    //         <AiOutlineEdit />
    //       </button>{' '}
    //       &nbsp;&nbsp;&nbsp;
    //       <button
    //         className={styles.deleteButton}
    //         onClick={() => {
    //           setDeleteId(row?._id);
    //           setShow(true);
    //         }}
    //       >
    //         <AiOutlineDelete />
    //       </button>
    //     </>
    //   ),
    // },
  ];

  const data = users;

  return (
    <>
      <div className={styles.settings}>
        <div className={styles.btnOuter}>
          <h3>Organizations</h3>
          {/* <button
            className={cn('button', styles.addButton)}
            type='button'
            onClick={() => history.push('/create-user')}
          >
            <AiOutlinePlus />
          </button> */}
        </div>
        <SearchMain onChange={(e) => setSearch(e.target.value)} />
        <DataTable
          columns={columns}
          data={data}
          pagination
          customStyles={customStyles}
        />
      </div>
      <DeleteUser
        show={show}
        id={deleteId}
        setShow={setShow}
        getPermissionListing={getUsersListing}
      />
      <ReportModal
        showModal={showModal}
        setShowModal={setShowModal}
        reportId={reportId}
        setReportId={setReportId}
        reportType={reportType}
        setReportType={setReportType}
      />
      <ChangeEIN
        organization={organization}
        changeEin={changeEin}
        setChangeEin={setChangeEin}
        getUsersListing={getUsersListing}
      />
      <TooltipGlodal />
    </>
  );
};

export default Organizations;
